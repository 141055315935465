export default {
  async getAppSettings ({ commit }) {
    let settings = []
    try {
      const res = await this.$axios.$get('info/app-settings?_where[app_brand.key_contains]=marketing&disabled=false')
      settings = res.result
    } catch (err) {
      // eslint-disable-next-line
      console.error('error fetching app settings...', err)
      commit('setAppSettings', [])
    }
    commit('setAppSettings', settings)
  }
}
