export default {
  async getProviderCategoriesV2 ({ commit }) {
    let providercategoriesV2 = []
    try {
      if (!this.$config.ENVIRONMENT.includes('production')) {
        const cookie = this.$cookies.get(this.$config.COOKIE_NAME)
        const sblxid = cookie ? cookie.id : null
        this.$axios.setHeader('SportBLXAuthorization', sblxid)
      }
      const res = await this.$axios.$get('info/categories?_where[parent_null]=true&disabled=false&_sort=orderIndex')
      providercategoriesV2 = res.result

      providercategoriesV2.forEach((category) => {
        category.categories = category.categories.sort((a, b) => {
          if (b.orderIndex === null || a.orderIndex < b.orderIndex) { return -1 }
          if (a.orderIndex === null || a.orderIndex > b.orderIndex) { return 1 }
          return 0
        })
      })
    } catch (err) {
      // eslint-disable-next-line
      console.error('error fetching provider categories...', err)
      commit('setProviderCategoriesV2', [])
    }
    commit('setProviderCategoriesV2', providercategoriesV2)
  },

  async getCategoryV2 ({ commit }, slug) {
    let category = {}
    try {
      if (!this.$config.ENVIRONMENT.includes('production')) {
        const cookie = this.$cookies.get(this.$config.COOKIE_NAME)
        const sblxid = cookie ? cookie.id : null
        this.$axios.setHeader('SportBLXAuthorization', sblxid)
      }
      const res = await this.$axios.$get(`info/categories?slug=${slug}&disabled=false`)
      category = res.result[0]
    } catch (err) {
      // eslint-disable-next-line
      console.error('error fetching category...', err)
      commit('setCategory', [])
    }
    commit('setCategory', category)
  },

  async getSubCategoryV2 ({ commit }, slug) {
    let category = {}
    try {
      if (!this.$config.ENVIRONMENT.includes('production')) {
        const cookie = this.$cookies.get(this.$config.COOKIE_NAME)
        const sblxid = cookie ? cookie.id : null
        this.$axios.setHeader('SportBLXAuthorization', sblxid)
      }
      const res = await this.$axios.$get(`info/categories?slug=${slug}&disabled=false`)
      category = res.result[0]
    } catch (err) {
      // eslint-disable-next-line
      console.error('error fetching category...', err)
      commit('setSubCategory', [])
    }
    commit('setSubCategory', category)
  }
}
