/**
 * All CMS services
 */
export class CmsService {
  // KEYS - SECTION
  static KEY_CATEGORY_PAGE_CARDS = 'SECTION_CATEGORY_PAGE_CARDS';
  static KEY_SECTION_CATEGORY_PAGE_CAROUSEL = 'SECTION_CATEGORY_PAGE_CAROUSEL';
  static KEY_SECTION_FOOTER = 'SECTION_FOOTER';
  static KEY_SECTION_HOMEPAGE_INVESTING_FOR_ALL = 'SECTION_HOMEPAGE_INVESTING_FOR_ALL';
  static KEY_SECTION_HOMEPAGE_INDUSTRY_RECORDS = 'SECTION_HOMEPAGE_INDUSTRY_RECORDS';
  static KEY_SECTION_HOMEPAGE_INDUSTRY_RECORDS_CAROUSEL = 'SECTION_HOMEPAGE_INDUSTRY_RECORDS_CAROUSEL';
  static KEY_SECTION_HOMEPAGE_CUSTOM_INVESTMENT = 'SECTION_HOMEPAGE_CUSTOM_INVESTMENT';
  static KEY_SECTION_HOMEPAGE_TESTIMONIALS_CAROUSEL = 'SECTION_HOMEPAGE_TESTIMONIALS_CAROUSEL';
  static KEY_SECTION_HOMEPAGE_HOW_IT_WORKS = 'SECTION_HOMEPAGE_HOW_IT_WORKS';
  static KEY_SECTION_HOMEPAGE_COMPANY_LOGOS = 'SECTION_HOMEPAGE_COMPANY_LOGOS';
  static KEY_SECTION_CATEGORIES_HOW_IT_WORKS_VIDEO = 'SECTION_CATEGORIES_HOW_IT_WORKS_VIDEO';
  // KEYS - TAB
  static KEY_TAB_COMPANY_PAGE_TERMS_AND_CONDITIONS = 'TAB_COMPANY_PAGE_TERMS_AND_CONDITIONS';
  static KEY_TAB_COMPANY_PAGE_PRIVACY_POLICY = 'TAB_COMPANY_PAGE_PRIVACY_POLICY';
  static KEY_TAB_COMPANY_PAGE_COOKIE_POLICY = 'TAB_COMPANY_PAGE_COOKIE_POLICY';
  static KEY_TAB_COMPANY_PAGE_FAQ = 'TAB_COMPANY_PAGE_FAQ';
  static KEY_TAB_COMPANY_PAGE_DISCLAIMER = 'TAB_COMPANY_PAGE_DISCLAIMER';
  // KEYS - TEXT
  static KEY_TEXT_COMPANY_NAME = 'TEXT_COMPANY_NAME';
  static KEY_TEXT_COMPANY_DESCRIPTION = 'TEXT_COMPANY_DESCRIPTION';
  static KEY_TEXT_COMPANY_EMAIL = 'TEXT_COMPANY_EMAIL';
  static KEY_TEXT_COMPANY_PHONE = 'TEXT_COMPANY_PHONE';
  static KEY_TEXT_COMPANY_ADDRESS = 'TEXT_COMPANY_ADDRESS';
  static KEY_TEXT_COMPANY_ICON = 'TEXT_COMPANY_ICON';
  static KEY_TEXT_COMPANY_LOGO = 'TEXT_COMPANY_LOGO';
  static KEY_TEXT_HOMEPAGE_TITLE = 'TEXT_HOMEPAGE_TITLE';
  static KEY_TEXT_HOMEPAGE_SIGNUP = 'TEXT_HOMEPAGE_SIGNUP';
  static KEY_TEXT_CATEGORY_NAME = 'TEXT_CATEGORY_NAME';
  static KEY_TEXT_CATEGORY_EXPLORE = 'TEXT_CATEGORY_EXPLORE';
  static KEY_TEXT_CATEGORY_RISK = 'TEXT_CATEGORY_RISK';
  static KEY_TEXT_CATEGORY_VIDEO_URL = 'TEXT_CATEGORY_VIDEO_URL';
  static KEY_TEXT_HOMEPAGE_BACKGROUND = 'TEXT_HOMEPAGE_BACKGROUND';
  static KEY_TEXT_HOMEPAGE_BACKGROUND_MOBILE = 'TEXT_HOMEPAGE_BACKGROUND_MOBILE';
  static KEY_TEXT_HOMEPAGE_BACKGROUND_TYPE = 'TEXT_HOMEPAGE_BACKGROUND_TYPE';
  static KEY_TEXT_CATEGORY_COVER_IMAGE = 'TEXT_CATEGORY_COVER_IMAGE';
  static KEY_TEXT_HOMEPAGE_SECTION_BACKGROUND = 'TEXT_HOMEPAGE_SECTION_BACKGROUND';
  // KEY - MODAL
  static KEY_MODAL_PROVIDER_INVEST_INCOME = 'MODAL_PROVIDER_INVEST_INCOME';
  static KEY_MODAL_PROVIDER_INVEST_OUTCOME = 'MODAL_PROVIDER_INVEST_OUTCOME';

  // Apply the app settings to the state
  static applyAppSettings(appSettings, state) {
    appSettings.forEach((setting) => {
      switch (setting.key) {
        // SECTION
        case (CmsService.KEY_CATEGORY_PAGE_CARDS):
          state.sectionCategoryPageCards = setting
          break
        case (CmsService.KEY_SECTION_CATEGORY_PAGE_CAROUSEL):
          state.sectionCategoryPageCarousel = setting
          break
        case (CmsService.KEY_SECTION_FOOTER):
          state.sectionFooter = setting
          break
        case (CmsService.KEY_SECTION_HOMEPAGE_INVESTING_FOR_ALL):
          state.sectionHomepageInvestingForAll = setting
          break
        case (CmsService.KEY_SECTION_HOMEPAGE_INDUSTRY_RECORDS):
          state.sectionHomepageIndustryRecords = setting
          break
        case (CmsService.KEY_SECTION_HOMEPAGE_INDUSTRY_RECORDS_CAROUSEL):
          state.sectionHomepageIndustryRecordsCarousel = setting
          break
        case (CmsService.KEY_SECTION_HOMEPAGE_CUSTOM_INVESTMENT):
          state.sectionHomepageCustomInvestment = setting
          break
        case (CmsService.KEY_SECTION_HOMEPAGE_TESTIMONIALS_CAROUSEL):
          state.sectionHomepageTestimonialsCarousel = setting
          break
        case (CmsService.KEY_SECTION_HOMEPAGE_HOW_IT_WORKS):
          state.sectionHomepageHowItWorks = setting
          break
        case (CmsService.KEY_SECTION_HOMEPAGE_COMPANY_LOGOS):
          state.sectionHomepageCompanyLogos = setting
          break
        case (CmsService.KEY_SECTION_CATEGORIES_HOW_IT_WORKS_VIDEO):
          state.sectionCategoriesHowItWorksVideo = setting
          break
        // TAB
        case (CmsService.KEY_TAB_COMPANY_PAGE_TERMS_AND_CONDITIONS):
          state.tabCompanyPageTermsAndConditions = setting
          break
        case (CmsService.KEY_TAB_COMPANY_PAGE_PRIVACY_POLICY):
          state.tabCompanyPagePrivacyPolicy = setting
          break
        case (CmsService.KEY_TAB_COMPANY_PAGE_COOKIE_POLICY):
          state.tabCompanyPageCookiePolicy = setting
          break
        case (CmsService.KEY_TAB_COMPANY_PAGE_FAQ):
          state.tabCompanyPageFaq = setting
          break
        case (CmsService.KEY_TAB_COMPANY_PAGE_DISCLAIMER):
          state.tabCompanyPageDisclaimer = setting
          break
        // TEXT
        case (CmsService.KEY_TEXT_COMPANY_NAME):
          state.textCompanyName = setting.value
          break
        case (CmsService.KEY_TEXT_COMPANY_EMAIL):
          state.textCompanyEmail = setting.value
          break
        case (CmsService.KEY_TEXT_COMPANY_PHONE):
          state.textCompanyPhone = setting.value
          break
        case (CmsService.KEY_TEXT_COMPANY_LOGO):
          state.textCompanyLogo = setting.value
          break
        case (CmsService.KEY_TEXT_HOMEPAGE_TITLE):
          state.textHomepageTitle = setting.value
          break
        case (CmsService.KEY_TEXT_HOMEPAGE_SIGNUP):
          state.textHomepageSignup = setting.value
          break
        case (CmsService.KEY_TEXT_CATEGORY_NAME):
          state.textCategoryName = setting.value
          break
        case (CmsService.KEY_TEXT_CATEGORY_EXPLORE):
          state.textCategoryExplore = setting.value
          break
        case (CmsService.KEY_TEXT_CATEGORY_RISK):
          state.textCategoryRisk = setting.value
          break
        case (CmsService.KEY_TEXT_CATEGORY_VIDEO_URL):
          state.textCategoryVideoUrl = setting.value
          break
        case (CmsService.KEY_TEXT_HOMEPAGE_BACKGROUND):
          state.textHomepageBackground = setting
          break
        case (CmsService.KEY_TEXT_HOMEPAGE_BACKGROUND_MOBILE):
          state.textHomepageBackgroundMobile = setting
          break
        case (CmsService.KEY_TEXT_HOMEPAGE_BACKGROUND_TYPE):
          state.textHomepageBackgroundType = setting.value
          break
        case (CmsService.KEY_TEXT_HOMEPAGE_SECTION_BACKGROUND):
          state.textHomepageSectionBackground = setting.value
          break
        // MODAL
        case (CmsService.KEY_MODAL_PROVIDER_INVEST_INCOME):
          state.modalProviderInvestIncome = setting
          break
        case (CmsService.KEY_MODAL_PROVIDER_INVEST_OUTCOME):
          state.modalProviderInvestOutcome = setting
          break
        default:
          return null
      }
    })
  }
}
