export default {
  providercategoriesV2 (state) {
    return state.providercategoriesV2
  },
  categoryV2 (state) {
    return state.categoryV2
  },
  subCategoryV2 (state) {
    return state.subCategoryV2
  }
}
