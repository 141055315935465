export default ({ app, store }) => {
  app.router.afterEach((to, from) => {
    if (to.path === from.path || process.server) {
      return
    }

    const top = store.getters['breadcrumb/get'][store.getters['breadcrumb/get'].length - 1]
    if (top && to.path === top.path) {
      store._actions['breadcrumb/popBreadcrumb'][0]()
      return
    }

    if (store.getters['breadcrumb/get'].length > 3 && to.path === '/') {
      store._actions['breadcrumb/downsize'][0]()
    }

    let name = from.path === '/' ? 'home' : from.name

    if (name === 'slug') {
      name = from.params.slug
    }

    name = name.replace(/-|_/g, ' ')

    store._actions['breadcrumb/addBreadcrumb'][0]({
      path: from.path,
      name,
      query: from.query
    })
  })
}
