export default {
  async getCategory ({ commit }, slug) {
    let category = {}
    try {
      if (!this.$config.ENVIRONMENT.includes('production')) {
        const cookie = this.$cookies.get(this.$config.COOKIE_NAME)
        const sblxid = cookie ? cookie.id : null
        this.$axios.setHeader('SportBLXAuthorization', sblxid)
      }
      const res = await this.$axios.$get(`info/categories?slug=${slug}&disabled=false`)

      if (res.result && res.result.length > 0) {
        category = res.result[0]
      }

      if (category.categories) {
        const sortedCategories = [...category.categories]
        category.categories = sortedCategories
          .sort((a, b) => {
            if (b.orderIndex === null || a.orderIndex < b.orderIndex) { return -1 }
            if (a.orderIndex === null || a.orderIndex > b.orderIndex) { return 1 }
            return 0
          })
      }
    } catch (err) {
      // eslint-disable-next-line
      console.error('error fetching provider categories...', err)
      commit('setCategory', {})
    }
    commit('setCategory', category)
  },

  async getSubCategory ({ commit, state }, slug) {
    let category = {}

    if (!state.category) {
      return
    }

    if (!slug) {
      commit('setSubCategory', {})
      return
    }

    try {
      if (!this.$config.ENVIRONMENT.includes('production')) {
        const cookie = this.$cookies.get(this.$config.COOKIE_NAME)
        const sblxid = cookie ? cookie.id : null
        this.$axios.setHeader('SportBLXAuthorization', sblxid)
      }
      const res = await this.$axios.$get(`info/categories?_where[parent.slug]=${state.category.slug}&disabled=false&slug=${slug}`)

      if (res.result && res.result.length > 0) {
        category = res.result[0]
      }
    } catch (err) {
      // eslint-disable-next-line
      console.error('error fetching provider categories...', err)
      commit('setSubCategory', {})
    }
    commit('setSubCategory', category)
  },

  async getProviders ({ commit, state }) {
    if (!state.category) { return }
    commit('setLoadingProviders', true)
    let providers = []
    let parentId
    let slug

    if (state.subCategory?.name) {
      parentId = state.category.id
      slug = state.subCategory.slug
    }

    if (state.category.name && !state.subCategory?.name) {
      parentId = null
      slug = state.category.slug
    }

    let getWhereParentQuery = ''

    if (!state.category.parent) {
      parentId = state.category.id
      getWhereParentQuery = `_where[category.parent]=${parentId}&`
    }

    try {
      if (!this.$config.ENVIRONMENT.includes('production')) {
        const cookie = this.$cookies.get(this.$config.COOKIE_NAME)
        const sblxid = cookie ? cookie.id : null
        this.$axios.setHeader('SportBLXAuthorization', sblxid)
      }
      const res = await this.$axios.$get(`/info/providers?${getWhereParentQuery}category.slug=${slug}&[category_null]=false&_sort=ordinal&hidden=false`)
      providers = res.result
      providers.forEach((provider) => {
        sortMedia(provider)
      })
      const advertisedProviders = providers.filter(provider => provider.advertise)
      providers = advertisedProviders
    } catch (err) {
      // eslint-disable-next-line
      commit('setProviders', [])
      commit('setLoadingProviders', false)
    }
    commit('setProviders', providers)
    commit('setLoadingProviders', false)
  },

  async savePresignupEmail({ commit }, email) {
    try {
      if (!this.$config.ENVIRONMENT.includes('production')) {
        const cookie = this.$cookies.get(this.$config.COOKIE_NAME)
        const sblxid = cookie ? cookie.id : null
        this.$axios.setHeader('SportBLXAuthorization', sblxid)
      }
      const result = await this.$axios.$post(`/info/pre-signup`, { email })
      commit('setPresignedUpResult', result)
    } catch (err) {
      // eslint-disable-next-line
      console.error('Error savePresignupEmail', err)
      commit('setPresignedUpResult', {})
    }
  },

  setOfferingsTab ({ commit }, tab) {
    commit('setOfferingsTab', tab)
  },

  setLoadingProviders ({ commit }, tab) {
    commit('setLoadingProviders', tab)
  },

  async getActiveProvider ({ commit, getters, state }, slug) {
    if (!slug || slug <= 0 || getters.activeProvider?.slug === slug) {
      return
    }
    commit('setTab', 0)

    let provider = getters.providersV2?.find(prov => prov?.slug === slug)

    if (!provider || provider === undefined) {
      // provider = get the provider from the backend
      try {
        if (!this.$config.ENVIRONMENT.includes('production')) {
          const cookie = this.$cookies.get(this.$config.COOKIE_NAME)
          const sblxid = cookie ? cookie.id : null
          this.$axios.setHeader('SportBLXAuthorization', sblxid)
        }
        const res = await this.$axios.$get(`/info/providers?_where[category.parent]=${state.subCategory.parent.id}&category.slug=${state.subCategory.slug}&slug=${slug}&_sort=ordinal&hidden=false`)
        sortMedia(res.result)
        provider = res.result
      } catch (err) {
        // eslint-disable-next-line
        commit('setActiveProvider', {})
        throw err
      }
      // commit set providers if it is missing
    }

    commit('setActiveProvider', provider?.[0])
  },

  setTab ({ commit }, tab) {
    commit('setTab', tab)
  },
}

function sortMedia(provider) {
  if (provider.videos && provider.videos.length > 1) {
    provider.videos = sortVideos(provider.videos)
  }
  if (provider.news_articles && provider.news_articles.length > 1) {
    provider.news_articles = sortNews(provider.news_articles)
  }
  if (provider.stats && provider.stats.dataStats) {
    provider.stats.dataStats = sortDataStats(provider.stats.dataStats, provider.sport.toLowerCase())
  }
  provider.sport = provider.sport && provider.sport.replace(/_/g, ' ')
}

function sortVideos(toSort) {
  let index = -1
  const hero = toSort.find((video, idx) => {
    if (video.hero) {
      index = idx
    }
    return video.hero
  })

  if (index > -1) {
    toSort.splice(index, 1)
  }

  toSort.sort((a, b) => {
    return new Date(b.base.date) - new Date(a.base.date)
  })

  if (index > -1) {
    toSort.unshift(hero)
  }
  return toSort
}

function sortNews(toSort) {
  toSort.sort((a, b) => {
    return new Date(b.base.date) - new Date(a.base.date)
  })
  return toSort
}

function sortDataStats(stats, sport) {
  switch (sport) {
    case 'thoroughbreds':
      stats = sortThoroughbreds(stats)
      return stats
    default:
      stats = sortBySeason(stats)
      return stats
  }
}

function sortThoroughbreds(toSort) {
  toSort.sort((a, b) => {
    return new Date(b.date) - new Date(a.date)
  })
  return toSort
}

function sortBySeason(toSort) {
  return toSort.reverse()
}
