export default {
  setCategory (state, newCategory) {
    state.category = newCategory
  },

  setSubCategory (state, newSubCategory) {
    state.subCategory = newSubCategory
  },

  setProviders (state, newProviders) {
    state.providers = newProviders
  },

  setOfferingsTab (state, tab) {
    state.offeringsTab = tab
  },

  setLoadingProviders (state, loadingProviders) {
    state.loadingProviders = loadingProviders
  },

  setTab (state, tab) {
    state.tab = tab
  },

  setActiveProvider (state, newProvider) {
    state.activeProvider = newProvider
  },

  setPresignedUpResult(state, presignedUpResult) {
    state.presignedUpResult = presignedUpResult
  }
}
