export default () => {
  return {
    appSettings: [],

    // SECTION
    sectionCategoryPageCards: null,
    sectionCategoryPageCarousel: null,
    sectionHomepageCustomInvestment: null,
    sectionFooter: null,
    sectionHomepageInvestingForAll: null,
    sectionHomepageIndustryRecords: null,
    sectionHomepageIndustryRecordsCarousel: null,
    sectionHomepageTestimonialsCarousel: null,
    sectionHomepageHowItWorks: null,
    sectionHomepageCompanyLogos: null,
    sectionCategoriesHowItWorksVideo: null,

    // TAB
    tabCompanyPageTermsAndConditions: null,
    tabCompanyPagePrivacyPolicy: null,
    tabCompanyPageCookiePolicy: null,
    tabCompanyPageFaq: null,
    tabCompanyPageDisclaimer: null,

    // TEXT
    textCategoryExplore: '',
    textCategoryName: '',
    textCategoryRisk: '',
    textCategoryVideoUrl: '',
    textCompanyName: '',
    textCompanyEmail: '',
    textCompanyPhone: '',
    textCompanyLogo: '',
    textHomepageTitle: '',
    textHomepageSignup: '',
    textHomepageBackground: null,
    textHomepageBackgroundMobile: null,
    textHomepageBackgroundType: '',
    textHomepageDescription: '',
    textHomepageDescriptionImage: '',
    textHomepageSectionBackground: '',

    // MODALS
    modalProviderInvestIncome: null,
    modalProviderInvestOutcome: null,

    // VIDEOS
    homepageBackgroundVideoMuted: true,
  }
}
