export default {
  addBreadcrumb({ commit }, page) {
    commit('pushBreadcrumb', page)
  },

  popBreadcrumb({ commit }) {
    commit('popBreadcrumb')
  },

  downsize({ commit }) {
    commit('removeFirst')
  }
}
