import { CmsService } from './services'

export default {
  setAppSettings (state, items) {
    state.appSettings = items

    CmsService.applyAppSettings(items, state)
  },

  toggleHomepageBackgroundVideoMuted (state) {
    state.homepageBackgroundVideoMuted = !state.homepageBackgroundVideoMuted
  }
}
