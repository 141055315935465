export default () => {
  return {
    category: {},
    subCategory: {},
    providers: [],
    activeProvider: {},
    tab: 0,
    offeringsTab: 0,
    loadingProviders: false,
    presignedUpResult: {}
  }
}
