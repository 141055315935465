import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'
import moment from 'moment'

export default ({ store }) => {
  const in60Minutes = moment()
    .add(60, 'minutes')
    .toDate()

  const isProduction = process.env.NODE_ENV.includes('production')

  createPersistedState({
    key: 'sblx-store',
    reducer: state => ({
      tempRoute: state.tempRoute,
    }),
    storage: {
      getItem: key => Cookies.get(key),
      // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
      setItem: (key, value) => {
        Cookies.set(key, value, { expires: in60Minutes, secure: isProduction })
      },
      removeItem: key => Cookies.remove(key)
    }
  })(store)
}
