export default {
  category (state) {
    return state.category
  },
  subCategory (state) {
    return state.subCategory
  },
  providers (state) {
    return state.providers
  },
  loadingProviders (state) {
    return state.loadingProviders
  },
  presignedUpResult(state) {
    return state.presignedUpResult
  }
}
