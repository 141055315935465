export default {
  setProviderCategoriesV2 (state, items) {
    state.providercategoriesV2 = items
  },
  setCategory (state, items) {
    state.categoryV2 = items
  },
  setSubCategory (state, items) {
    state.subCategoryV2 = items
  }
}
