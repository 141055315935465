export default {
  async getProviders ({ commit, getters }) {
    let providers = []
    try {
      if (!this.$config.ENVIRONMENT.includes('production')) {
        const cookie = this.$cookies.get(this.$config.COOKIE_NAME)
        const sblxid = cookie ? cookie.id : null
        this.$axios.setHeader('SportBLXAuthorization', sblxid)
      }
      const res = await this.$axios.$get('/info/providers')
      providers = res.result
      providers.forEach((provider) => {
        sortMedia(provider)
      })
    } catch (err) {
      // eslint-disable-next-line
      console.error(`failed to get providers ${this.$axios.defaults.baseURL + '/info/providers'} , error is:`, err)
      commit('setProviders', [])
    }
    commit('setProviders', providers)
  },

  async getActiveProvider ({ commit, getters }, id) {
    if (!id || id <= 0 || getters.activeProvider.provider_id === id) {
      return
    }
    commit('setTab', 0)

    let provider = getters.providers.find(prov => prov.provider_id === id)

    if (!provider || provider === undefined) {
      // provider = get the provider from the backend
      try {
        if (!this.$config.ENVIRONMENT.includes('production')) {
          const cookie = this.$cookies.get(this.$config.COOKIE_NAME)
          const sblxid = cookie ? cookie.id : null
          this.$axios.setHeader('SportBLXAuthorization', sblxid)
        }
        const res = await this.$axios.$get(`/info/providers/${id}`)
        sortMedia(res.result)
        provider = res.result
      } catch (err) {
        // eslint-disable-next-line
        commit('setActiveProvider', {})
        throw err
      }
      // commit set providers if it is missing
    }

    commit('setActiveProvider', provider)
  },

  // getProviderStats ({ dispatch, getters }) {
  //   const { stats, sport, id } = getters.activeProvider
  //   if (!stats.summaryStats) {
  //     const summaryStats = calculateSummaryStats(stats, sport.toLowerCase())
  //     const newStats = Object.assign({}, { summaryStats, dataStats: stats })
  //     dispatch('providers/updateProviderStats', { id, newStats }, { root: true })
  //   }
  // },

  setTab ({ commit }, tab) {
    commit('setTab', tab)
  },

  setOfferingsTab ({ commit }, tab) {
    commit('setOfferingsTab', tab)
  },

  addPortfolioContext ({ commit, getters }, tab) {
    const active = getters.activeProvider
    if (!active) {
      return
    }
    commit('setPortfolioContext', {
      name: active.name,
      accreditedLink: active.accredited_link,
      nonAccreditedLink: active.non_accredited_link,
      closed: active.closed
    })
  },

  resetPortfolioContext({ commit }) {
    commit('setPortfolioContext', null)
  }

  // updateProviderStats ({ commit, getters }, { id, newStats }) {
  //   const selectedProviderIndex = getters.providers.findIndex(prov => prov.id === id)
  //   const updatedProvider = Object.assign(getters.providers[selectedProviderIndex], {
  //     stats: newStats
  //   })
  //   commit('updateProvider', updatedProvider, selectedProviderIndex)
  //   commit('setActiveProvider', updatedProvider)
  // }
}

function sortMedia(provider) {
  if (provider.videos && provider.videos.length > 1) {
    provider.videos = sortVideos(provider.videos)
  }
  if (provider.news_articles && provider.news_articles.length > 1) {
    provider.news_articles = sortNews(provider.news_articles)
  }
  if (provider.stats && provider.stats.dataStats) {
    provider.stats.dataStats = sortDataStats(provider.stats.dataStats, provider.sport.toLowerCase())
  }
  provider.sport = provider.sport && provider.sport.replace(/_/g, ' ')
}

function sortVideos(toSort) {
  let index = -1
  const hero = toSort.find((video, idx) => {
    if (video.hero) {
      index = idx
    }
    return video.hero
  })

  if (index > -1) {
    toSort.splice(index, 1)
  }

  toSort.sort((a, b) => {
    return new Date(b.base.date) - new Date(a.base.date)
  })

  if (index > -1) {
    toSort.unshift(hero)
  }
  return toSort
}

function sortNews(toSort) {
  toSort.sort((a, b) => {
    return new Date(b.base.date) - new Date(a.base.date)
  })
  return toSort
}

function sortDataStats(stats, sport) {
  switch (sport) {
    case 'thoroughbreds':
      stats = sortThoroughbreds(stats)
      return stats
    default:
      stats = sortBySeason(stats)
      return stats
  }
}

function sortThoroughbreds(toSort) {
  toSort.sort((a, b) => {
    return new Date(b.date) - new Date(a.date)
  })
  return toSort
}

function sortBySeason(toSort) {
  return toSort.reverse()
}
