export default {
  pushBreadcrumb (state, page) {
    if (state.breadcrumb.length === 0 || (state.breadcrumb[(state.breadcrumb.length - 1)].name !== page.name)) {
      state.breadcrumb.push(page)
    }
  },

  popBreadcrumb (state) {
    state.breadcrumb.pop()
  },

  removeFirst(state) {
    state.breadcrumb.splice(0, 1)
  }
}
