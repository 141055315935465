export default {
  setProviders (state, providers) {
    state.providers = providers
  },

  updateProvider (state, provider, index) {
    state.providers[index] = provider
  },

  setActiveProvider (state, provider) {
    state.activeProvider = provider
  },

  setTab (state, tab) {
    state.tab = tab
  },

  setOfferingsTab (state, tab) {
    state.offeringsTab = tab
  },

  setPortfolioContext (state, context) {
    state.portfolioContext = context
  }
}
