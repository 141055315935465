export default {
  async getToggles ({ commit }, pageName) {
    try {
      const url = `${this.$config.TOGGLE_URL}/proxy?appName=marketing-site-v3&environment=${this.$config.ENVIRONMENT}&pageName=${pageName || ''}`
      this.$axios.setHeader('Authorization', this.$config.TOGGLE_AUTH)
      const res = await this.$axios.$get(url)
      if (res.toggles.length) {
        const mapped = {}

        res.toggles.forEach((toggle) => {
            const name = toggle.name
            mapped[name] = true
        })
        commit('setToggles', mapped)
      } else {
        commit('setToggles', {})
      }
    } catch (err) {
      commit('setToggles', {})
    } finally {
      this.$axios.setHeader('Authorization', false)
    }
  }
}
