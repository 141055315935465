export default {
  providers (state) {
    return state.providers
  },

  activeProvider (state) {
    return state.activeProvider
  },

  tab (state) {
    return state.tab
  },

  offeringsTab (state) {
    return state.offeringsTab
  },

  portfolioContext (state) {
    return state.portfolioContext
  }
}
